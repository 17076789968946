export const Home = ()=>{
    return(
        <div>
            <div class="page-intro" >
                <div><h1 class="s"> Zoftvare Labs</h1></div>
            </div>
            {/* <div>
                <img class="process" src="./Process.png"></img>
            </div> */}
            <div className="row form map-container nb no-padding">
                
                <div className="col-sm-12 col-lg-6 short-info intro">
                    <h1> Craft your tech solutions</h1>
                    <h3 className="service-tag" > With Tailored software from our labs </h3>
                    {/* <h5 className="service-info" > Transforming digital visions into immersive web experiences with exceptional design that functions seamlessly, and drives results.</h5> */}
                    <a className="cbtn" href="/services">
                        <button className="cbtn" >See what we do --></button>
                    </a>
                </div>
                <div className="col-sm-12 col-lg-6 ">
                    <img className="intro-img" src="./ZOFTVARE_LABS.png"></img>
                </div>
            </div>
            <div className="about-us  portfolio">
                    <h1> Our Process</h1>
                    <div className="row r">
                        <div className="col-sm-12 col-lg-2 principle process-padding">
                            <h3>1</h3>
                            <img src="./req.png" class="process-logo"></img>
                            <p>Requirement gathering</p>  
                        </div>
                        <div className="col-sm-12 col-lg-2 principle process-padding">
                            <h3>2</h3>
                            <img src="./design.png" class="process-logo "></img>
                            <p>Design</p>  
                        </div>
                        <div className="col-sm-12 col-lg-2 principle process-padding">
                            <h3>3</h3>
                            <img src="./develop.png" class="process-logo"></img>
                            <p>Develop</p>  
                        </div>
                        <div className="col-sm-12 col-lg-2 principle process-padding">
                            <h3>4</h3>
                            <img src="./testing.png" class="process-logo"></img>
                            <p>Testing</p>  
                        </div>
                        <div className="col-sm-12 col-lg-2 principle process-padding">
                            <h3>5</h3>
                            <img src="./deploy.png" class="process-logo"></img>
                            <p>Deployment/Release</p>  
                        </div>
                        <div className="col-sm-12 col-lg-2 principle process-padding">
                            <h3>6</h3>
                            <img src="./updates.png" class="process-logo"></img>
                            <p>Updates & Maintenace</p>  
                        </div>
                    </div>
            </div>

            <div className="about-us projects portfolio black">
                    <h1> Project Portfolio</h1>
                    <div className="row priniple-Container">
                        <div className="col-sm-12 col-lg-4 principle">
                            <img src="./customer.png" class="principle-logo"></img>
                            <h2>Projects taken</h2>
                            <h2>
                                4
                            </h2>
                        </div>
                        <div className="col-sm-12 col-lg-4 principle">
                            <img src="./handshake.png" class="principle-logo"></img>
                            <h2>Projects Delivered</h2>
                            <h2>
                                2
                            </h2>
                        </div>
                        <div className="col-sm-12 col-lg-4 principle">
                            <img src="./software.png" class="principle-logo"></img>
                            <h2>Under updation</h2>
                            <h2>
                                1
                            </h2>
                        </div>
                    </div>
                    <a className="home-btn" href="/contact">
                        <button className="cbtn home-btn" >Contact Us -></button>
                    </a>
            </div>
        </div>
    )
}