
export const Services = ()=>{
    return(
        <div>
        <div class="page-intro" >
            <div><h1 class="s">Our Services</h1></div>
        </div>
            <div className="row form map-container nb">
                <div className="col-sm-12 col-lg-6">
                    <img className="spic" src="./web.png"></img>
                </div>
                <div className="col-sm-12 col-lg-6 short-info">
                    <h1> Website Development</h1>
                    <h3 className="service-tag" > Elevate your online presence with us. </h3>
                    <h5 className="service-info" > Transforming digital visions into immersive web experiences with exceptional design that functions seamlessly, and drives results.</h5>
                    <a className="cbtn" href="/contact">
                        <button className="cbtn" >Get it done --></button>
                    </a>
                </div>
            </div>
            <div className="x">
                <hr></hr>
            </div>
            <div class="row form map-container nb">
                <div class="col-sm-12 col-lg-6 order-lg-2">
                    <img class="spic" src="./app.png" alt="App Image"></img>
                </div>
                <div class="col-sm-12 col-lg-6 order-lg-1 short-info">
                    <h1>App Development</h1>
                    <h3 className="service-tag">Seamless apps, Stunning design: where iOS and Android meet excellence!</h3>
                    <h5 class="service-info">Unlocking Cross-Platform Potential: Elevating Mobile Experiences with Robust Design for iOS and Android.</h5>
                    <a class="cbtn" href="/contact">
                        <button class="cbtn">Get it done --></button>
                    </a>
                </div>
            </div>

            <div className="x">
                <hr></hr>
            </div>
             <div className="row form map-container nb">
                <div className="col-sm-12 col-lg-6">
                    <img className="spic" src="./AI.png"></img>
                </div>
                <div className="col-sm-12 col-lg-6 short-info">
                    <h1>AI-empowered Solutions</h1>
                    <h3 className="sucess" > Unlocking the future with AI-infused innovation</h3>
                    <h5 className="service-info" > Experience the future with our AI-driven software solutions – transforming possibilities into realities, </h5>
                    <a className="cbtn" href="/contact">
                        <button className="cbtn" >Get it done --></button>
                    </a>
                </div>
            </div>
            <div className="x">
                <hr></hr>
            </div>
            <div class="row form map-container nb">
                <div class="col-sm-12 col-lg-6 order-lg-2">
                    <img class="spic" src="./DigitalMarketing.png" alt="App Image"></img>
                </div>
                <div class="col-sm-12 col-lg-6 order-lg-1 short-info">
                    <h1>Digital Marketing</h1>
                    <h3 class="service-tag">Creating Waves in the Digital Ocean!</h3>
                    <h5 class="service-info">Ignite Your Brand's Online presence, drive results, and connect with Your Audience Effectively.</h5>
                    <a class="cbtn" href="/contact">
                        <button class="cbtn">Get it done --></button>
                    </a>
                </div>
            </div>
            <br></br>
            <br></br>
        </div>
    )
}