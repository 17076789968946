import { Card } from "../components/card";

export const Team = ()=>{
    return(
        <div>
            <div class="page-intro team-margin" >
                <div><h1 class="s"> Meet the team</h1></div>
            </div>
            <div className="row form map-container nb">
                <div className="col-sm-12 col-lg-4">
                    <Card
                        name="Sanjith Kumar "
                        role="Mobile App Developer"
                        imageURL="./sanjith.png"
                        profileURL="https://www.linkedin.com/in/sanjithkumarm/"
                        mailId="sanjithkumarm2003@gmail.com"
                    /> 
                </div>
                <div className="col-sm-12 col-lg-4 ">
                    <Card
                        name="Sethulakshmanan"
                        role="AI/ML Engineer"
                        imageURL="./sethu.jpg"
                        profileURL="https://www.linkedin.com/in/sp-sethulakshmanan-588277227/"
                        mailId="sethulakshmanan11@gmail.com"
                    /> 
                </div>
                <div className="col-sm-12 col-lg-4">
                    <Card
                        name="Sivaganesh"
                        role="Website Developer"
                        imageURL="./siva.png"
                        profileURL="https://www.linkedin.com/in/sivaganesh-natarajavel-567a56237/"
                        mailId="sivaganeshnatarajavel@gmail.com"
                    /> 
                </div>
            </div>
            <br></br>
            <div className="x">
                <hr></hr>
            </div>
            {/* row 2 */}
            <div className="row form map-container nb">
                <div className="col-sm-12 col-lg-4">
                    <Card
                        name="Vasithra"
                        role="AI/ML Engineer"
                        imageURL="./vasithra.png"
                        profileURL="https://www.linkedin.com/in/vasithra-d-262a5722a/"
                        mailId="vasithra1103@gmail.com"
                    /> 
                </div>
                <div className="col-sm-12 col-lg-4 ">
                    <Card
                        name="Vigram"
                        role="Mobile App Developer"
                        imageURL="./vigram.jpg"
                        profileURL="https://www.linkedin.com/in/vigram-k-m/"
                        mailId="vigramkm@gmail.com"
                    /> 
                </div>
                <div className="col-sm-12 col-lg-4">
                    <Card
                        name="Shamlin"
                        role="Website Developer"
                        imageURL="./zander.png"
                        profileURL="https://www.linkedin.com/in/yogesh-shamlin-shinanth-j-s/"
                        mailId="yogeshshamlin@gmail.com"
                    /> 
                </div>
            </div>
            <br></br>
            <div className="x">
                <hr></hr>
            </div>
            <br></br>
            <div class="page-intro motto">
                <h2 class="s">"Unlocking Innovation, Crafting Solutions"</h2>
            </div>
            <br></br>
            <div className="x">
                <hr></hr>
            </div>
            <br></br>
        </div>
    )
}