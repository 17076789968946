
export const About = ()=>{
    return(
        <div>
        <div class="page-intro" >
            <div><h1 class="s">About Us</h1></div>
        </div>
            <div className="row form map-container nb">
                <div className="col-sm-12 col-lg-6">
                    <img className="spic grp" src="./Group.png"></img>
                </div>
                <div className="col-sm-12 col-lg-6 short-info">
                    <h1> Who we are ?</h1>
                    <h3 className="service-tag" > A dynamic team of creative developers </h3>
                    <h5 className="service-info team-info" > Meet our dynamic team of creative developers, the driving force behind websites, apps, and AI-powered solutions, backed by a dedicated digital marketing unit. We excel in crafting seamless user experiences, harnessing AI for smarter solutions, and elevating your online presence. Join us to redefine the future of your digital journey.</h5>
                    <a className="cbtn" href="/team">
                        <button className="cbtn" >Meet the team -></button>
                    </a>
                </div>
            </div>
            <div className="x">
                <hr></hr>
            </div>
            <div className="about-us">
                    <h1> Our Principles</h1>
                    <div className="row priniple-Container">
                        <div className="col-sm-12 col-lg-4 principle">
                            <img src="./customer.png" class="principle-logo"></img>
                            <h2>Customer-First Approach</h2>
                            <p>
                                Prioritizing the needs and satisfaction of customers above all else, ensuring their well-being and success is at the forefront of every decision and action.
                            </p>
                        </div>
                        <div className="col-sm-12 col-lg-4 principle">
                            <img src="./handshake.png" class="principle-logo"></img>
                            <h2>Long-Term Commitment</h2>
                            <p>
                                Demonstrating a dedication to sustainability, both in terms of environmental responsibility and enduring relationships with customers, and employees.
                            </p>
                        </div>
                        <div className="col-sm-12 col-lg-4 principle">
                            <img src="./software.png" class="principle-logo"></img>
                            <h2>Harness Software's Power</h2>
                            <p>
                                Focusing on unlocking and maximizing the benefits of software applications to empower and enhance the lives of individuals and organizations.
                            </p>
                        </div>
                    </div>
                    <a className="cbtn" href="/contact">
                        <button className="cbtn" >Contact Us -></button>
                    </a>
            </div>
            <br></br>
            <br></br>
        </div>
    )
}