import {Link} from "react-router-dom";

export const Footer = ()=>{
    return (
            <footer className="footer">
            <div class="conatiner bottom-container">

                <div class="container-sm-12 container-md-6 container-lg-4">
                    <ul class="navbar-nav ms-auto px-2 mx-auto bold">
                    <li class="nav-item px-2">
                    <h3>Quick Links:</h3>
                    </li>

                    <li class="nav-item px-2">
                        <a class="nav-link " aria-current="page" href="/">Home</a>
                    </li>
                    <li class="nav-item px-2">
                        <a class="nav-link" href="/services">Services</a>
                    </li>
                    {/* <li class="nav-item px-2">
                        <a class="nav-link" href="events.html">Events</a>
                    </li> */}
                    <li class="nav-item px-2">
                        <a class="nav-link" href="/about">About</a>
                    </li>
                    <li class="nav-item px-2">
                        <a class="nav-link" href="/contact">Contact</a>
                    </li>
                    <li class="nav-item px-2">
                        <a class="nav-link" href="/team">Our Team</a>
                    </li>
                    </ul>
                </div>
                <div className="container-sm-12 container-md-6 container-lg-4">
                    <h3>Reach us :</h3>
                    <ul class="navbar-nav ms-auto px-2 mx-auto reach" >
                        <li><a target="_blank" rel="noreferrer" className="footer-link " href="https://www.linkedin.com/in/zoftvare-labs/"> <i class="fa-brands fa-linkedin fa-xl"></i> </a></li>
                        <li><a target="_blank" rel="noreferrer" className="footer-link " href="https://github.com/ZoftwareLabs"> <i class="fa-brands fa-github fa-xl"></i> </a></li>
                        <li><a target="_blank" rel="noreferrer" className="footer-link " href="https://www.instagram.com/zoftvarelabs"> <i class="fa-brands fa-instagram fa-xl"></i> </a></li>
                        <li><a target="_blank" href="mailto:zoftvarelabs@gmail.com" className="footer-link " > <i class="fa-solid fa-envelope fa-xl"></i> </a></li>
                        <li><a class="nav-link" href="tel:+91 9962034271"><i class="fa-solid fa-phone fa-xl"></i></a></li>
                        {/* <li><a target="_blank" rel="noreferrer" className="footer-link " href="https://earth.google.com/web/search/33.80065299518774,+-114.53205404369159/@33.80066884,-114.53204689,108.45890353a,247.72060588d,35y,0.00000001h,44.4010633t,360r/data=CmoaQBI6Gd44Kcx75kBAIS5tOCwNolzAKiYzMy44MDA2NTI5OTUxODc3NCwgLTExNC41MzIwNTQwNDM2OTE1ORgBIAEiJgokCXRYuScHUUBAEcBx76bHqjlAGZkBD7kGsFjAIZfnp0hVelzA"><i class="fa-sharp fa-solid fa-location-dot fa-xl"></i></a></li> */}
                        <br></br>
                    </ul>
                </div>
            </div>
            <br></br>
            <p className="cpyrt">© 2023 <img src="./ZOFTVARE_LABS.png" class="logo top-logo"></img>  Z O F T V A R E   <span> L A B S</span>   </p>
    </footer>
    
    )
}