export const Contact = ()=>{
    return(
        <div>
        <div class="page-intro" >
            <div><h1 class="s">Contact Us</h1></div>
        </div>
        <div className="row form map-container">
            <div className="col-sm-12 col-lg-6">
                <iframe class="map" src=" https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248755.79476275246!2d80.04419928339739!3d13.047807810998808!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265ea4f7d3361%3A0x6e61a70b6863d433!2sChennai%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1698770914816!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="col-sm-12 col-lg-6 short-info">
                <h1>Why us ?</h1>
                <h1>We measure our success,</h1>
                <h2 className="sucess" > by your business's progress!</h2>
                <br></br>
                <a class="phone" href="tel:+91 9962034271"><i class="fa-solid fa-phone fa-xl"></i> Call us!</a>
                <br></br>
                <br></br>
                <h4>Adress details will be added soon!</h4>
            </div>
        </div>

        <div className="row form">
            <div className="col-sm-12 col-lg-6">
                <img className="form-img" src="./ZOFTVARE_LABS.png"></img>
            </div>

            <div className="col-sm-12 col-lg-6">
                <form action="https://formsubmit.co/zoftvarelabs@gmail.com" method="POST">
                    <h3>Leave us a message</h3>
                    <input type="text" id="name" name="name" placeholder="Your Name" required></input>
                    <input type="email" id="email" name="email" placeholder="Your Email" required></input>
                    <input type="tel" pattern="[0-9]{10}" required id="phone" name="phone" placeholder="Phone no." required></input>
                    <textarea id="Project info" rows="4" name="Project info" placeholder="Tell us about your project / need"></textarea>
                    <button type="submit">Send</button>
                </form>
            </div>
        </div>
    </div>
    )
}