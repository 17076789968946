import React from 'react';

export const Card = ({ name, role,imageURL , profileURL, mailId }) => {
  return (
    <div className="card-mine">
      <img className="card-img" src={imageURL} alt={name} />
      <div className="card-content">
        <h2>{name}</h2>
        <p className="role" >{role}</p>
        <ul class="navbar-nav ms-auto px-2 mx-auto bold" >
            <li class="nav-item px-2 contact" ><a target="_blank" rel="noreferrer" className="footer-link " href={profileURL}> <i class="fa-brands fa-linkedin fa-xl"></i> </a></li>
            <li class="nav-item px-2 contact" ><a target="_blank" href={`mailto:${mailId}`} className="footer-link " > <i class="fa-solid fa-envelope fa-xl"></i> </a></li>
        </ul>
        {/* <a href={`mailto:${mailId}`}>{mailId}</a> */}
      </div>
    </div>
  );
};
