import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navbar } from "./components/navbar";
import { Footer } from "./components/footer";
import { Home } from "./pages/home";
import { Services } from "./pages/services";
import { About } from "./pages/about";
import { Contact } from "./pages/contact";
import { Team } from "./pages/team";


function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element = {<Home />} />
          <Route path="/about" element = {<About />} />
          <Route path="/services" element = {<Services />} />
          <Route path="/contact" element = {<Contact />} />
          <Route path="/team" element = {<Team />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
